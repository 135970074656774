import React from "react"
import { Link, graphql } from "gatsby"
import Moment from "react-moment"

import Seo from "../components/seo"
import NewsletterForm from "../components/newsletterForm"
import Layout from "../components/layout"

const NewsletterPage = ({ data }) => {
  return (
    <Layout>
      <Seo title={"The home of Personal Development"} />
      <section className="hero is-dark is-small">
        <div className="hero-body">
          <div className="container pb-6 ">
            <div className="columns is-centered mt-6">
              <div className="column is-7 has-text-centered">
                <h1 className="is-size-3 has-text-weight-bold has-text-white title-font">
                  For a better you
                </h1>
                <p className="has-text-white">
                  Join our constantly growing newsletter and receive a carefuly
                  curated summary of the best resources to become a better
                  version of yourself.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section mb-0 pb-0">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <NewsletterForm />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6 is-relative ">
              <div className="content">
                <label className="label" htmlFor="sd">
                  Past editions
                </label>
                {data ? (
                  data.allStrapiSentmails.edges.map((mail, index) => {
                    return (
                      <div className="py-3" key={mail.node.strapiId}>
                        <Link
                          to={mail.node.strapiId}
                          className="is-block has-text-dark"
                        >
                          <p className="is-size-3 has-text-weight-bold my-0">
                            {mail.node.title}
                          </p>
                          <p className="has-opacity-65">
                            published at{" "}
                            <Moment format="MMM Do YYYY">
                              {mail.node.publishedAt}
                            </Moment>
                          </p>
                        </Link>
                      </div>
                    )
                  })
                ) : (
                  <p className="">
                    Our first edition is yet to come. Stay tuned!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiSentmails {
      edges {
        node {
          strapiId
          content
          publishedAt
          title
        }
      }
    }
  }
`

export default NewsletterPage
